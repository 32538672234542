var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      staticClass: "left-nav-menu",
      attrs: {
        "default-active": _vm.activeMenu,
        collapse: _vm.isCollapse,
        router: true
      }
    },
    [
      _c("el-menu-item", { on: { click: _vm.toggleCollapse } }, [
        _vm.isCollapse
          ? _c("i", { staticClass: "el-icon-s-unfold" })
          : _c("i", { staticClass: "el-icon-menu" })
      ]),
      _c("el-menu-item", { attrs: { index: "1", route: "/mnt" } }, [
        _c("i", { staticClass: "el-icon-s-home" }),
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("首页")
        ])
      ]),
      _vm._l(_vm.menuList, function(item, index) {
        return _c(
          "el-submenu",
          { key: index, attrs: { index: item.navIndex } },
          [
            _c("template", { slot: "title" }, [
              _c("i", { class: item.icon }),
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(item.name))
              ])
            ]),
            _vm._l(item.children, function(it, ind) {
              return _c(
                "el-menu-item",
                { key: ind, attrs: { index: it.navIndex, route: it.url } },
                [_vm._v("\n\t\t\t\t" + _vm._s(it.name) + "\n\t\t\t")]
              )
            })
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }