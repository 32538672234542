<template>
    <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/mnt' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item
            v-for="(item, index) in breadcrumbs"
            :key="index"
        >
            <a style="cursor: pointer;" href="javascript:;" @click.prevent.stop="breadcrumbClick(item.path)">{{item.breadcrumbName}}</a>
        </el-breadcrumb-item>
    </el-breadcrumb>
</template>
<script>
export default {
	methods: {
		breadcrumbClick (path) {
			if (this.$route.path !== path) {
				this.$root.replace(path);
			}
		}
	},
	computed: {
		breadcrumbs () {
			return this.$store.state.main.breadcrumbs;
		}
	}
};
</script>