<!--含有 Form表单的 dialog-->
<template>
    <el-dialog
        :visible.sync="dialogFormVisible"
        :title="options.title"
        :width="options.width"
        :show-close="false"
    >
        <slot></slot>
    </el-dialog>
</template>
<script>
export default {
	props: {
		dialogFormVisible: {
			type: Boolean,
			default: false
		},
		options: {
			type: Object,
			default: function () {
				return {
					width: '50%',
					title: '表格弹框'
				};
			}
		}
	}
};
</script>