<!--管理后台父容器-->
<template>
	<el-container style="height: 100%;">
		<el-header class="header">
			<div class="header__left">
				<i class="el-icon-s-promotion"></i>
				<span class="title__comp">瑞云医</span>
			</div>
			<div style="display: flex;">
				<div class="header__right" style="cursor: pointer;" @click="toCashOut">
					<span>待审核提现数：</span>
					<el-badge :value="cashoutCount" :max="99"></el-badge>
				</div>
				<div class="header__right" style="cursor: pointer;" @click="toAppointment">
					<span>待处理药品单数：</span>
					<el-badge :value="appoinmentCount" :max="99"></el-badge>
				</div>
				<div class="header__right">
					<span class="nick-name">{{nickName || '管理员'}}</span>
					<el-dropdown trigger="click" size="medium" @command="handleCommand">
						<i class="el-icon-arrow-down"></i>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="changePwd">修改密码</el-dropdown-item>
							<el-dropdown-item command="logout">退出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</el-header>
		<el-container>
			<el-aside class="left-nav" id="leftNav" style="width: 200px;height: auto;">
				<side-bar></side-bar>
			</el-aside>
			<el-main style="padding: 0px 20px 30px 20px;">
				<bread-crumb style="margin: 20px 0;"></bread-crumb>
				<p class="nav-name" v-text="navName"></p>
				<router-view></router-view>
			</el-main>
		</el-container>
		<form-dialog :dialogFormVisible="dialogFormVisible" :options="formOptions">
			<el-form :model="form">
				<el-form-item label="手机号" :label-width="formLabelWidth">
					<el-input v-model="form.phone" :disabled="true" class="global-form-input"></el-input>
				</el-form-item>
				<!-- <el-form-item label="验证码" :label-width="formLabelWidth">
            <el-input v-model="form.code" class="global-form-input"></el-input>
            <el-button
              type="primary"
              style="margin-left: 20px;"
              @click="sendVCode"
              :disabled="vCodeDisabled"
            >{{vCodeText}}</el-button>
          </el-form-item> -->
				<el-form-item label="密码" :label-width="formLabelWidth">
					<el-input v-model="form.oldPassword" class="global-form-input" show-password></el-input>
				</el-form-item>
				<el-form-item label="确认密码" :label-width="formLabelWidth">
					<el-input v-model="form.password" class="global-form-input" show-password></el-input>
				</el-form-item>
			</el-form>
			<div class="global-dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="modifyPwd">确 定</el-button>
			</div>
		</form-dialog>
	</el-container>
</template>
<script>
import SideBar from './Side-bar';
import formDialog from './common/form-dialog';
import BreadCrumb from './Breadcrumb.vue';
export default {
	data () {
		return {
			dialogFormVisible: false,
			form: {
				phone: '',
				// code: '', // 短信验证码
				password: '',
				oldPassword: ''
			},
			vCodeText: '发送验证码',
			vCodeDisabled: false,
			formLabelWidth: '120px',
			formOptions: {
				width: '40%',
				title: '修改密码'
			},
			nickName: '',
			navName: '',
			appoinmentCount: 0,
			cashoutCount: 0,
			interval: null
		};
	},
	created () {
		this.nickName = JSON.parse(window.localStorage.getItem('smt_ui')).nickname;
		this.form.phone = JSON.parse(window.localStorage.getItem('smt_ui')).phone;
		this.navName = this.$route.meta.navName;
		this.getAppointmentWaitStockout();
	},
	mounted () {
		this.interval = setInterval(() => {
			this.getAppointmentWaitStockout();
		}, 5000);
	},
	destroyed () {
		if (this.interval) {
			clearInterval(this.interval);
		}
	},
	watch: {
		'$route.meta.navName': {
			handler: function (val, old) {
				setTimeout(() => {
					if (val !== old) {
						this.navName = this.$route.meta.navName;
					}
				});
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		getAppointmentWaitStockout () {
			this.$root.request('appointmentWaitStockout', {
				phone: this.form.phone
			}, null, false).then(data => {
				if (data) {
					this.appoinmentCount = data.appoinmentCount;
					this.cashoutCount = data.cashoutCount;
				}
			});
		},
		toAppointment () {
			this.$root.go('/mnt/appointment');
		},
		toCashOut () {
			this.$root.go('/mnt/cashout');
		},
		/**
		 * 管理员下拉菜单中选项的点击事件
		 */
		handleCommand (command) {
			switch (command) {
			case 'changePwd':
				this.changePassword();
				break;
			case 'logout':
				this.logout();
				break;
			default:
				break;
			}
		},
		// 修改密码
		changePassword () {
			this.dialogFormVisible = true;
		},
		// 退出登录
		logout () {
			window.localStorage.clear();
			this.$root.go('/login');
		},
		// 发送验证码
		sendVCode () {
			this.$root.request('sendVerifyCode', {
				phone: this.form.phone
			})
				.then(data => {
					if (data) {
						let n = 60;
						const si = setInterval(() => {
							if (n <= 0) {
								this.vCodeText = '发送验证码';
								this.vCodeDisabled = false;
								clearInterval(si);
							} else {
								if (!this.vCodeDisabled) {
									this.vCodeDisabled = true;
								}
								this.vCodeText = `${n--}s`;
							}
						}, 1000);
					}
				});
		},
		modifyPwd () {
			if (!this.form.phone || !this.form.password || !this.form.oldPassword) {
				this.$root.elMsg('请输入完整信息', 'warning');
			} else {
				this.$root.request('passwordModify', {
					oldPassword: this.form.oldPassword,
					phone: this.form.phone,
					password: this.form.password
				})
					.then(data => {
						if (data) {
							this.$root.elMsg('修改成功');
							this.dialogFormVisible = false;
							this.logout();
						}
					});
			}
		}
	},
	components: {
		SideBar,
		BreadCrumb,
		formDialog
	}
};
</script>

<style lang="less" scoped>
	.header {
		background-color: #438eb9;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 60px;
		font-size: 14px;
		color: white;

		&__left {
			font-size: 25px;
		}

		&__right {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 50px;
			min-width: 90px;
			// line-height: 50px;
			box-shadow: 0px 0px 4px 2px #2e82af;
			margin-left: 30px;
			padding: 0 6px;
			.nick-name {
				margin-right: 10px;
			}

			.el-icon-arrow-down {
				color: white;
				cursor: pointer;
			}
		}
		/deep/ sup {
			border: none;
		}
	}

	.left-nav {
		height: 100%;
	}

	.nav-name {
		border-bottom: 1px solid #d5e3ef;
		font-size: 21px;
		color: #478fca !important;
		padding-bottom: 5px;
	}
</style>