var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-breadcrumb",
    { attrs: { separator: "/" } },
    [
      _c("el-breadcrumb-item", { attrs: { to: { path: "/mnt" } } }, [
        _vm._v("首页")
      ]),
      _vm._l(_vm.breadcrumbs, function(item, index) {
        return _c("el-breadcrumb-item", { key: index }, [
          _c(
            "a",
            {
              staticStyle: { cursor: "pointer" },
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.breadcrumbClick(item.path)
                }
              }
            },
            [_vm._v(_vm._s(item.breadcrumbName))]
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }