<!-- 侧边栏 组件 -->
<template>
    <el-menu class="left-nav-menu"
        :default-active="activeMenu"
        :collapse="isCollapse"
        :router="true"
    >
        <el-menu-item @click="toggleCollapse">
            <i v-if="isCollapse" class="el-icon-s-unfold"></i>
            <i v-else class="el-icon-menu"></i>
        </el-menu-item>
        <el-menu-item index="1" route="/mnt">
            <i class="el-icon-s-home"></i>
            <span slot="title">首页</span>
        </el-menu-item>
		<el-submenu v-for="(item, index) in menuList" :key="index" :index="item.navIndex">
			<template slot="title">
                <i :class="item.icon"></i>
                <span slot="title">{{item.name}}</span>
            </template>
			<el-menu-item
				v-for="(it, ind) of item.children"
				:key="ind"
				:index="it.navIndex"
				:route='it.url'
			>
				{{it.name}}
			</el-menu-item>
		</el-submenu>
    </el-menu>
</template>
<script>
export default {
	data () {
		return {
			activeMenu: '1',
			defaultOpen: [],
			isCollapse: false,
			menuList: []
		};
	},
	created () {
		this.activeMenu = this.$route.meta.navIndex;
		this.getMenus();
	},
	methods: {
		toggleCollapse () {
			this.isCollapse = !this.isCollapse;
			document.getElementById('leftNav').style.width = this.isCollapse ? '64px' : '200px';
		},
		getMenus () {
			const vPath = this.$route.path;
			this.$root.request('getMenus', {
				id: JSON.parse(window.localStorage.getItem('smt_ui')).id
			}).then(data => {
				if (data) {
					this.menuList = data;
				}
			});
		}
	},
	computed: {
		vuePath () {
			return this.$route.path;
		}
	},
	watch: {
		vuePath (val, oldVal) {
			if (val !== oldVal) {
				this.activeMenu = this.$route.meta.navIndex;
				if (this.$route.query.navIndex) {
					this.activeMenu = this.$route.query.navIndex;
				}
				const splits = this.activeMenu.split('-');
				if (splits.length) {
					this.defaultOpen = [this.activeMenu.split('-')[0]];
				}
			}
		}
	}
};
</script>
<style lang="less" scoped>
    .left-nav-menu {
      height: 100%;
      background-color: #f8f8f8;
      li {
        border-bottom: 1px solid #e5e5e5;
      }
    }
</style>